import { createContext, useContext, useState, ReactNode } from "react";

export interface IHistoryFilters {
  categories: string[];
  dateRange: {
    startDate: Date | null;
    endDate: Date | null;
  };
}

interface HistoryFiltersContextType {
  filters: IHistoryFilters;
  updateFilters: (filters: IHistoryFilters) => void;
  isLoading: boolean;
  setIsLoading: (loading: boolean) => void;
  isExporting: boolean;
  setIsExporting: (exporting: boolean) => void;
}

const HistoryFiltersContext = createContext<
  HistoryFiltersContextType | undefined
>(undefined);

export const HistoryFiltersProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [filters, setFilters] = useState<IHistoryFilters>({
    categories: [],
    dateRange: {
      startDate: null,
      endDate: null,
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isExporting, setIsExporting] = useState(false);

  const updateFilters = (newFilters: IHistoryFilters) => {
    setFilters(newFilters);
  };

  return (
    <HistoryFiltersContext.Provider
      value={{
        filters,
        updateFilters,
        isLoading,
        setIsLoading,
        isExporting,
        setIsExporting,
      }}
    >
      {children}
    </HistoryFiltersContext.Provider>
  );
};

export const useHistoryFilters = () => {
  const context = useContext(HistoryFiltersContext);
  if (!context) {
    throw new Error(
      "useHistoryFilters must be used within a HistoryFiltersProvider"
    );
  }
  return context;
};
