import { useEffect, useState } from "react";
import style from "./MainView.module.css";
import {
  SlideNotification,
  UnauthorizedMessage,
  validateModuleAccess,
} from "../../resources";
import CustomerTable from "./CustomerTable/CustomerTable";
import HeaderWithSearchAndForm from "./HeaderWithSearchAndForm/HeaderWithSearchAndForm";
import Cards from "./Cards/Cards";
import { adaptCustomersToTableFormat } from "@Adapters/adaptCustomersToTableFormat";
import useScreenWidth from "@Hooks/useScreenWidth/useScreenWidth";
import { LoadingPulseIcon } from "@Models/icons";
import { SHOW_CUSTOMERS_UUID } from "@Models/consts/securityAccess";

const MainView = () => {
  const [customersList, setCustomersList] = useState([]);
  const [stateResponse, setStateResponse] = useState<any>({
    message: "",
    status: 0,
  });
  const [currentPagination, setCurrentPagination] = useState(1);
  const [maxPages, setMaxPages] = useState<number>(1);
  const [isLoadingCustomersTable, setIsLoadingCustomersTable] =
    useState<boolean>(true);
  const [criticalError, setCriticalError] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const screenWidth = useScreenWidth();

  // Cache object to store fetched page data
  const [pageCache, setPageCache] = useState<{ [key: number]: any }>({});

  const fetchData = async (page) => {
    setIsLoadingCustomersTable(true);
    if (pageCache[page]) {
      //  If the page is already in cache, use the cached data
      const cachedData = pageCache[page];
      setCustomersList(cachedData.customers);
      setMaxPages(cachedData.totalPages);
      setIsLoadingCustomersTable(false);
    } else {
      // If the page is not in cache, make the server call
      const adaptedData = await adaptCustomersToTableFormat(
        page,
        setCriticalError
      );
      if (adaptedData.isSuccess) {
        const newData = {
          customers: adaptedData.data,
          totalPages: adaptedData.totalPages,
        };
        // Store the data in cache
        setPageCache((prevCache) => ({
          ...prevCache,
          [page]: newData,
        }));
        setCustomersList(adaptedData.data);
        setMaxPages(adaptedData.totalPages);
        setIsLoadingCustomersTable(false);
      } else {
        setCustomersList([]);
        setIsLoadingCustomersTable(false);
        setStateResponse({
          message: "Error al obtener los clientes. Intente nuevamente.",
          status: 400,
        });
      }
    }
    setIsLoadingCustomersTable(false);
  };

  useEffect(() => {
    fetchData(currentPagination);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPagination]);

  const handlePageChange = (pageNumber) => {
    setCurrentPagination(pageNumber);
  };

  if (loader)
    return (
      <div
        id="viuti-front-mainContent"
        style={{
          overflow: "auto",
        }}
        className={style.container}
      >
        <figure className={style.loadingIcon}>
          <img src={LoadingPulseIcon} alt="Cargando..." />
        </figure>
      </div>
    );

  return (
    <div
      id="viuti-front-mainContent"
      style={{
        overflow: "auto",
      }}
      className={style.container}
    >
      <div className={style.mainContainer}>
        <section className={style.content}>
          <section className={style.upperPart}>
            <HeaderWithSearchAndForm
              setStateResponse={setStateResponse}
              setLoader={setLoader}
            />
          </section>
          {screenWidth >= 900 && <hr className={style.hr} />}
          <section className={style.mainPart}>
            {screenWidth > 900 && <h2 className={style.title}>Clientes</h2>}
            {validateModuleAccess(SHOW_CUSTOMERS_UUID, false) ? (
              <CustomerTable
                customersList={customersList}
                currentPagination={currentPagination}
                setCurrentPagination={handlePageChange}
                maxPages={maxPages}
                isLoadingCustomersTable={isLoadingCustomersTable}
                criticalError={criticalError}
              />
            ) : (
              <div className={style.unauthorized}>
                <UnauthorizedMessage
                  restrictedFeatureName={"Listado de clientes"}
                  restrictedUUID={SHOW_CUSTOMERS_UUID}
                />
              </div>
            )}
          </section>
        </section>
        <Cards setStateResponse={setStateResponse} />
      </div>
      <SlideNotification
        state={stateResponse}
        clearStatus={() => setStateResponse({ message: "", status: 0 })}
      />
    </div>
  );
};

export default MainView;
