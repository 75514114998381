import React, { useEffect } from "react";
import style from "./Booking.module.css";
import {
  ActiveCalendarIcon,
  InactiveCalendarIcon,
  QueuedCalendarIcon,
} from "@Models/icons";
import {
  TableWrapper,
  SlideNotification,
  PaginationWrapper,
} from "../../../../resources";
import useGetReservations from "@Hooks/useGetReservations/useGetReservations";

const Booking = ({ idClient }) => {
  const [currentPagination, setCurrentPagination] = React.useState<number>(1);
  const { reservation, dataTable, stateResponse } =
    useGetReservations(idClient);
  const [status, setStatus] = React.useState(stateResponse);
  const [data, setData] = React.useState(dataTable);

  useEffect(() => {
    const reservationsListPaginated = dataTable.slice(
      (currentPagination - 1) * 10,
      currentPagination * 10
    );
    setData(reservationsListPaginated);
  }, [currentPagination, dataTable]);

  useEffect(() => {
    setStatus(stateResponse);
  }, [stateResponse]);

  return (
    <section className={style.containerBooking}>
      <div className={style.cardReservation}>
        <article>
          <figure>
            <img src={ActiveCalendarIcon} alt="Imagen calendario activo" />
          </figure>
          <p>Número de reservas</p>
          <h2>{reservation.totalReservations}</h2>
        </article>
        <article>
          <figure>
            <img src={QueuedCalendarIcon} alt="Imagen calendario en cola" />
          </figure>
          <p>Porcentaje de reprogramadas</p>
          <h2>{reservation.percentageRescheduled}</h2>
        </article>
        <article>
          <figure>
            <img src={InactiveCalendarIcon} alt="Imagen calendario inactivo" />
          </figure>
          <p>Porcentaje de citas no atendidas</p>
          <h2>{reservation.percentageUnattended}</h2>
        </article>
      </div>
      <TableWrapper
        titles={["Fecha", "Hora", "Detalles"]}
        data={data}
        containerStyle={{ width: "465px" }}
        headerStyle={{
          backgroundColor: "#937CF4",
          color: "#FFF",
          fontSize: "11px",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "normal",
          letterSpacing: "0.14px",
          borderRight: "1px solid #937CF4",
          borderLeft: "1px solid #937CF4",
        }}
        // bodyStyle={ }
        // cellStyle={{ width: 155 }}
      />
      {dataTable.length !== 0 && (
        <PaginationWrapper
          currentPagination={currentPagination}
          setCurrentPagination={setCurrentPagination}
          optionsPerPage={10}
          options={dataTable}
        />
      )}
      <SlideNotification
        state={status}
        clearStatus={() => setStatus({ message: "", status: 0 })}
      />
    </section>
  );
};

export default Booking;
