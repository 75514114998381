import { getSaleDocumentDetail } from "@Services/clients/getSaleDocumentDetail";
import { ApiResponse } from "@Services/constServices";
import { IGroupedSaleItems, ISaleDocumentDetail } from "@Interfaces/sales";

const groupSaleItems = (items: ISaleDocumentDetail[]): IGroupedSaleItems => {
  return items.reduce(
    (groups, item) => {
      if (item.packageId) {
        groups.packages.push(item);
      } else if (item.localProductId) {
        groups.products.push(item);
      } else if (item.priceServiceId) {
        if (item.reserveId && !item.comandaId) {
          const reserveId = item.reserveId.toString();
          groups.appointments[reserveId] = groups.appointments[reserveId] || [];
          groups.appointments[reserveId].push(item);
        } else if (item.comandaId) {
          const commandId = item.comandaId.toString();
          groups.commands[commandId] = groups.commands[commandId] || [];
          groups.commands[commandId].push(item);
        } else {
          groups.services.push(item);
        }
      }
      return groups;
    },
    {
      services: [],
      products: [],
      packages: [],
      appointments: {},
      commands: {},
    } as IGroupedSaleItems
  );
};

export const adaptSaleDocumentDetail = async (
  saleDocumentId: number,
  saleBoxId: number
): Promise<ApiResponse> => {
  try {
    const response = await getSaleDocumentDetail(saleDocumentId, saleBoxId);

    if (!response.isSuccess) {
      return {
        isSuccess: false,
        data: null,
        status: response.status,
        error: response.error,
      };
    }

    const paymentMethods = response.data.paymentMethod.map((payment) => ({
      name:
        payment.methodPayment.charAt(0).toUpperCase() +
        payment.methodPayment.slice(1).toLowerCase(),
      amount: payment.amount,
    }));

    const groupedItems = groupSaleItems(response.data.saleDocumentDetails);

    return {
      isSuccess: true,
      data: {
        groupedItems,
        paymentMethods,
        saleDocument: response.data.saleDocument,
        change: response.data.change,
      },
      status: response.status,
      error: response.error,
    };
  } catch (error) {
    return {
      isSuccess: false,
      data: null,
      status: 500,
      error: error,
    };
  }
};
