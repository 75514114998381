import React, { useEffect } from "react";
import style from "./Cards.module.css";
import {
  ThreePeopleIcon,
  CashIcon,
  // TwoPeopleIcon,
  LoadingPulseIcon,
  AltArrowDownIcon,
} from "../../../models/icons";
// import { clientsBeingServedAdapter } from "@Adapters/clientsBeingServedAdapter";
import { customersAttendedTodayAndAverageTicketsAdapter } from "@Adapters/customersAttendedTodayAndAverageTicketsAdapter";
import { Icon, Dropdown, DualBars } from "../../../resources";
import useScreenWidth from "@Hooks/useScreenWidth/useScreenWidth";

const Cards = ({ setStateResponse }) => {
  const [infoClient, setInfoClient] = React.useState({
    // clientsBeingServed: 0,
    clientsServedToday: 0,
    ticketAverage: "0",
  });
  const [isLoading, setIsLoading] = React.useState({
    loadingResponse1: true,
    loadingResponse2: true,
  });
  const screenWidth = useScreenWidth();
  useEffect(() => {
    const fetchInfo = async () => {
      // const response1 = await clientsBeingServedAdapter(
      //   setIsLoading,
      //   setStateResponse
      // );
      const response2 = await customersAttendedTodayAndAverageTicketsAdapter(
        setIsLoading,
        setStateResponse
      );
      setInfoClient({
        // clientsBeingServed: response1.clientsBeingServed,
        clientsServedToday: response2.clientsServedToday,
        ticketAverage: response2.ticketAverage,
      });
    };
    fetchInfo();
  }, []);

  // const titleOne =
  //   screenWidth > 1100 ? "Clientes siendo atendidos" : "Siendo atendidos";
  const titleTwo =
    screenWidth > 1100 ? "Clientes atendidos hoy" : "Atendidos hoy";
  const titleThree = "Ticket promedio";
  const [showDropdown, setShowDropdown] = React.useState(false);
  return (
    <div className={style.rightPart}>
      <ul className={style.cardsContainer}>
        {screenWidth <= 500 && (
          <div className={style.containerButtonDropD}>
            <button
              onClick={() => setShowDropdown(!showDropdown)}
              className={`${style.iconTable} ${
                showDropdown ? style.iconActive : style.iconInactive
              }`}
            >
              <Icon
                path={AltArrowDownIcon}
                size={15}
                alt={"flecha"}
                color={"#fff"}
              />
            </button>
          </div>
        )}
        {/* {screenWidth > 500 && (
          <li className={`${style.card} ${style.card_one}`}>
            <span className={style.imageContainer}>
              <figure>
                <img src={TwoPeopleIcon} alt="Clientes siendo atendidos" />
              </figure>
            </span>
            <span className={style.textContainer}>
              {isLoading.loadingResponse1 ? (
                <DualBars />
              ) : (
                <>
                  <p className={style.words}>{titleOne}</p>
                  <span className={style.numbers}>
                    {infoClient.clientsBeingServed}
                  </span>
                  {screenWidth > 1100 && (
                    <span className={style.clients}>clientes</span>
                  )}
                </>
              )}
            </span>
            <span className={style.buttonContainer}> */}
        {/* <figure className={style.altArrowRightIcon}>
            <img
              src={AltArrowRightIcon}
              alt="Botón para ver clientes siendo atendidos"
            />
          </figure> */}
        {/* </span>
          </li>
        )} */}
        <li className={`${style.card} ${style.card_two}`}>
          <span className={style.imageContainer}>
            <figure>
              <img src={ThreePeopleIcon} alt="Clientes atendidos hoy" />
            </figure>
          </span>
          <span className={style.textContainer}>
            {isLoading.loadingResponse2 ? (
              <DualBars />
            ) : (
              <>
                <p className={style.words}>{titleTwo}</p>
                <p className={style.numbers}>{infoClient.clientsServedToday}</p>
              </>
            )}
          </span>
        </li>
        {screenWidth > 500 && (
          <li className={`${style.card} ${style.three}`}>
            <span className={style.imageContainer}>
              <figure>
                <img src={CashIcon} alt="Icono representando un billete" />
              </figure>
            </span>
            <span className={style.textContainer}>
              {isLoading.loadingResponse2 ? (
                <DualBars />
              ) : (
                <>
                  <p className={style.words}>{titleThree}</p>
                  <p className={style.numbers}>S/ {infoClient.ticketAverage}</p>
                </>
              )}
            </span>
          </li>
        )}
      </ul>

      <Dropdown
        isOpen={showDropdown}
        id="dropdownCardsClients"
        transitionDurationInMs={200}
      >
        <div className={style.dropDownContainer}>
          {/* {screenWidth <= 500 && (
            <li className={`${style.card} ${style.card_one}`}>
              <span className={style.imageContainer}>
                <figure>
                  <img src={TwoPeopleIcon} alt="Clientes siendo atendidos" />
                </figure>
              </span>
              <span className={style.textContainer}>
                <p className={style.words}>{titleOne}</p>
                {isLoading.loadingResponse1 ? (
                  <Icon path={LoadingPulseIcon} size={70} alt={"Cargando"} />
                ) : (
                  <span className={style.numbers}>
                    {infoClient.clientsBeingServed}
                  </span>
                )}
                <span className={style.clients}>clientes</span>
              </span>
              <span className={style.buttonContainer}> */}
          {/* <figure className={style.altArrowRightIcon}>
            <img
              src={AltArrowRightIcon}
              alt="Botón para ver clientes siendo atendidos"
            />
          </figure> */}
          {/* </span>
            </li>
          )} */}

          {screenWidth <= 500 && (
            <li className={`${style.card} ${style.three}`}>
              <span className={style.imageContainer}>
                <figure>
                  <img src={CashIcon} alt="Icono representando un billete" />
                </figure>
              </span>
              <span className={style.textContainer}>
                <p className={style.words}>{titleThree}</p>
                {isLoading.loadingResponse2 ? (
                  <Icon path={LoadingPulseIcon} size={70} alt={"Cargando"} />
                ) : (
                  <p className={style.numbers}>S/ {infoClient.ticketAverage}</p>
                )}
              </span>
            </li>
          )}
        </div>
      </Dropdown>
    </div>
  );
};

export default Cards;
