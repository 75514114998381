import { IHistoryFilters } from "@Contexts/historyFiltersContext";
import { getCustomerHistoryExport } from "@Services/clients/getCustomerHistoryExport";
import { ApiResponse } from "@Services/constServices";

export const adaptCustomerHistoryExport = async (
  idClient: string,
  filters: IHistoryFilters
): Promise<ApiResponse> => {
  try {
    const response = await getCustomerHistoryExport(idClient, filters);

    if (!response.isSuccess) {
      return {
        isSuccess: false,
        status: response.status,
        error: response.error,
        data: null,
      };
    }

    // Descarga automática del archivo
    const link = document.createElement("a");
    link.href = `data:text/csv;base64,${response.data.result}`;
    link.download = `historial-cliente-${idClient}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    return {
      isSuccess: true,
      status: response.status,
      error: null,
      data: response.data.result,
    };
  } catch (error) {
    return {
      isSuccess: false,
      status: 400,
      error: error,
      data: null,
    };
  }
};
