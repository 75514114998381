import { useState, useRef, useEffect } from "react";
import style from "./MultiSelect.module.css";
import { AltArrowDownIcon } from "@Models/icons";
// @ts-ignore
import { Icon } from "@viuti/recursos";

export interface Option {
  value: string;
  label: string;
}

export interface MultiSelectProps {
  name?: string;
  label?: string;
  options: Option[];
  value: string[];
  onChange: (values: string[]) => void;
  placeholder?: string;
  disabled?: boolean;
  error?: string;
  required?: boolean;
}

export const MultiSelect = ({
  name,
  label,
  options,
  value = [],
  onChange,
  placeholder = "Seleccionar...",
  disabled = false,
  error,
  required = false,
}: MultiSelectProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleToggleOption = (optionValue: string) => {
    const newValue = value.includes(optionValue)
      ? value.filter((v) => v !== optionValue)
      : [...value, optionValue];
    onChange(newValue);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const selectedLabels = options
    .filter((option) => value.includes(option.value))
    .map((option) => option.label)
    .join(", ");

  return (
    <div className={style.container} ref={dropdownRef}>
      {label && (
        <label className={style.label} htmlFor={name}>
          {label}
          {required && <span className={style.required}>*</span>}
        </label>
      )}

      <div
        className={`${style.select} ${isOpen ? style.open : ""} ${
          error ? style.error : ""
        }`}
        onClick={() => !disabled && setIsOpen(!isOpen)}
        role="combobox"
        aria-expanded={isOpen}
        aria-haspopup="listbox"
        aria-disabled={disabled}
      >
        <div className={style.selectedValues}>
          {selectedLabels || placeholder}
        </div>
        <div className={`${style.arrow} ${isOpen ? style.open : ""}`}>
          <Icon path={AltArrowDownIcon} color="#4D4D4D" size={12} />
        </div>
      </div>

      {isOpen && !disabled && (
        <div className={style.dropdown} role="listbox">
          {options.map((option) => (
            <div
              key={option.value}
              className={`${style.option} ${
                value.includes(option.value) ? style.selected : ""
              }`}
              onClick={() => handleToggleOption(option.value)}
              role="option"
              aria-selected={value.includes(option.value)}
            >
              <span className={style.checkbox}>
                {value.includes(option.value) && "✓"}
              </span>
              {option.label}
            </div>
          ))}
        </div>
      )}

      {error && <span className={style.errorMessage}>{error}</span>}
    </div>
  );
};
