import style from "./HistoryRowDetails.module.css";
// @ts-ignore
import { ModalAcceptCancel } from "@viuti/recursos";

export const HistoryRowDetailsSkeleton = () => {
  return (
    <ModalAcceptCancel
      title={"Detalles de la venta"}
      showButtons={{
        showButtonClose: true,
        showButtonOne: false,
        showButtonTwo: false,
      }}
    >
      <div className={style.containerDetailsHistory}>
        <div className={`${style.headerSection} ${style.skeleton}`}>
          {[1, 2, 3].map((i) => (
            <article key={i}>
              <div className={style.skeletonLine} style={{ width: "100px" }} />
              <div>
                <div
                  className={style.skeletonLine}
                  style={{ width: "150px" }}
                />
              </div>
            </article>
          ))}
        </div>

        <div className={`${style.paymentSection} ${style.skeleton}`}>
          <div className={style.sectionTitle}>Medios de pago</div>
          {[1, 2].map((i) => (
            <article key={i}>
              <div className={style.skeletonLine} style={{ width: "120px" }} />
              <div>
                <div className={style.skeletonLine} style={{ width: "80px" }} />
              </div>
            </article>
          ))}
        </div>

        <div className={`${style.saleDetailsSection} ${style.skeleton}`}>
          <div className={style.sectionTitle}>Detalle de la venta</div>
          {[1, 2, 3].map((i) => (
            <div key={i} className={style.itemGroup}>
              <div
                className={style.skeletonLine}
                style={{ width: "150px", marginBottom: "1rem" }}
              />
              {[1, 2].map((j) => (
                <div key={j} className={style.saleItem}>
                  <div className={style.itemHeader}>
                    <div className={style.itemInfo}>
                      <div className={style.skeletonCircle} />
                      <div
                        className={style.skeletonLine}
                        style={{ width: "200px" }}
                      />
                    </div>
                    <div
                      className={style.skeletonLine}
                      style={{ width: "80px" }}
                    />
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>

        <div className={`${style.totalSection} ${style.skeleton}`}>
          {[1, 2, 3, 4, 5].map((i) => (
            <article key={i}>
              <div className={style.skeletonLine} style={{ width: "100px" }} />
              <div>
                <div
                  className={style.skeletonLine}
                  style={{ width: "100px" }}
                />
              </div>
            </article>
          ))}
        </div>
      </div>
    </ModalAcceptCancel>
  );
};
