import { ApiResponse, baseUrl, fetchData } from "@Services/constServices";
// @ts-ignore
import { trackUserAction } from "@viuti/recursos";
import { IHistoryFilters } from "@Contexts/historyFiltersContext";

export const getCustomerServiceHistory = async (
  idClient: string,
  page: number,
  filters?: IHistoryFilters
): Promise<ApiResponse> => {
  const queryParams = new URLSearchParams({
    customerId: idClient,
    page: page.toString(),
  });

  if (filters?.categories?.length) {
    queryParams.append("categoryId", filters.categories.join("-"));
  }

  if (filters?.dateRange?.startDate) {
    const startDate = filters.dateRange.startDate.toISOString().split("T")[0];
    queryParams.append("startDate", startDate);
  }

  if (filters?.dateRange?.endDate) {
    const endDate = filters.dateRange.endDate.toISOString().split("T")[0];
    queryParams.append("endDate", endDate);
  }

  const url = `${baseUrl}/GetCustomerHistory?${queryParams.toString()}`;

  const ingresa_vista_historial_del_cliente = 10;
  trackUserAction(ingresa_vista_historial_del_cliente);

  return fetchData(url);
};
