import style from "./History.module.css";
import {
  TableWrapper,
  PaginationWrapper,
  TableMobile,
} from "../../../../resources";
import useScreenWidth from "@Hooks/useScreenWidth/useScreenWidth";
import { CommandIcon } from "@Models/icons";
//@ts-ignore
import { NoInfoToShow } from "@viuti/recursos";
import { adaptDataTableMobile } from "./adaptDataMobile/adaptDataMobile";
import { HistoryRowDetails } from "./HistoryRowDetails/HistoryRowDetails";
import { HistoryFilters } from "./HistoryFilters/HistoryFilters";
import { useHistoryFilters } from "@Contexts/historyFiltersContext";
import useGetHistory from "@Hooks/useGetHistory/useGetHistory";
import { useParams } from "react-router-dom";

interface IHistoryProps {
  activeButton: string;
}

const History = ({ activeButton }: IHistoryProps) => {
  const { id: clientId } = useParams();
  const screenWidth = useScreenWidth();
  const { isLoading } = useHistoryFilters();
  const {
    dataTable,
    dataTableMobile,
    showModalDetailsHistory,
    setShowModalDetailsHistory,
    numberOfPages,
    setCurrentPagination,
    currentPagination,
  } = useGetHistory({ clientId, activeButton });
  const moreThanOneButton = numberOfPages > 1;

  if (dataTable?.length === 0 && !isLoading) {
    return (
      <section>
        <NoInfoToShow
          title="Historial vacío"
          message="No se hallaron registros para este cliente."
          icon={CommandIcon}
        />
      </section>
    );
  }
  return (
    <section className={style.containerHistory}>
      <HistoryFilters />
      {screenWidth >= 900 ? (
        <TableWrapper
          titles={["N° de documento", "Fecha", "Hora", "Cajero", "Total", "-"]}
          data={dataTable}
          isLoading={isLoading}
          cellStyle={{ width: "150px" }}
        />
      ) : (
        <TableMobile
          isLoading={isLoading}
          dataTable={dataTableMobile.map((data, index) =>
            adaptDataTableMobile(data, index, setShowModalDetailsHistory)
          )}
        />
      )}

      <HistoryRowDetails
        showModalDetailsHistory={showModalDetailsHistory}
        setShowModalDetailsHistory={setShowModalDetailsHistory}
      />

      {moreThanOneButton && (
        <PaginationWrapper
          currentPagination={currentPagination}
          setCurrentPagination={setCurrentPagination}
          optionsPerPage={10}
          numberOfButtons={numberOfPages}
        />
      )}
    </section>
  );
};

export default History;
