import {
  ApiResponse,
  cashregisterBaseUrl,
  fetchData,
} from "@Services/constServices";

export const getSaleDocumentDetail = async (
  saleDocumentId: number,
  saleBoxId: number
): Promise<ApiResponse> => {
  const url = `${cashregisterBaseUrl}/SalesDocuments/SalesDocumentDetail?saleDocumentId=${saleDocumentId}&saleBoxId=${saleBoxId}`;
  return fetchData(url);
};
