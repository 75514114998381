import { useEffect, useState } from "react";
import { adaptCustomerServiceHistory } from "@Adapters/adaptCustomerServiceHistory";
import { useClientsContext } from "@Contexts/clientsContext";
import { useHistoryFilters } from "@Contexts/historyFiltersContext";

interface IUseGetHistoryProps {
  clientId: string;
  activeButton: string;
}

const useGetHistory = ({ clientId, activeButton }: IUseGetHistoryProps) => {
  const { filters, setIsLoading } = useHistoryFilters();
  const [dataTable, setDataTable] = useState([]);
  const [dataTableMobile, setDataTableMobile] = useState<any>([]);
  const [showModalDetailsHistory, setShowModalDetailsHistory] = useState(null);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const { setNotification } = useClientsContext();
  const [currentPagination, setCurrentPagination] = useState<number>(1);

  const fetchHistoryData = async () => {
    setIsLoading(true);
    try {
      const response = await adaptCustomerServiceHistory(
        clientId,
        currentPagination,
        setShowModalDetailsHistory,
        filters
      );
      if (response.isSuccess) {
        setDataTable(response.data.dataTable);
        setDataTableMobile(response.data.dataTableMobile);
        setNumberOfPages(response.data.maxPages);
      } else {
        setNotification({
          message: "Error al obtener la información del historial del cliente",
          status: 400,
        });
      }
    } catch (error) {
      setNotification({
        message: "Error al obtener el historial",
        status: 400,
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (activeButton === "history") {
      fetchHistoryData();
    }
  }, [clientId, activeButton, currentPagination, filters]);

  return {
    dataTable,
    showModalDetailsHistory,
    setShowModalDetailsHistory,
    dataTableMobile,
    numberOfPages,
    setCurrentPagination,
    currentPagination,
  };
};

export default useGetHistory;
