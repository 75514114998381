// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LDV0ci3jGHS36lvR7MgW {
  background: none;
  border: none;
  color: #937cf4;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  padding: 0 0 8px;
  text-align: left;
  transition: all 0.2s ease;
}

.LDV0ci3jGHS36lvR7MgW:hover {
  text-decoration: underline;
  color: #45348e;
}

.LDV0ci3jGHS36lvR7MgW:active {
  transform: scale(0.98);
}
`, "",{"version":3,"sources":["webpack://./src/pages/CustomerDetail/views/History/adaptDataMobile/adaptDataMobile.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,YAAY;EACZ,cAAc;EACd,eAAe;EACf,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,yBAAyB;AAC3B;;AAEA;EACE,0BAA0B;EAC1B,cAAc;AAChB;;AAEA;EACE,sBAAsB;AACxB","sourcesContent":[".detailsButton {\n  background: none;\n  border: none;\n  color: #937cf4;\n  cursor: pointer;\n  font-size: 14px;\n  font-weight: 500;\n  padding: 0 0 8px;\n  text-align: left;\n  transition: all 0.2s ease;\n}\n\n.detailsButton:hover {\n  text-decoration: underline;\n  color: #45348e;\n}\n\n.detailsButton:active {\n  transform: scale(0.98);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"detailsButton": `LDV0ci3jGHS36lvR7MgW`
};
export default ___CSS_LOADER_EXPORT___;
