import style from "./HistoryRowDetails.module.css";
// @ts-ignore
import { ModalAcceptCancel, Icon } from "@viuti/recursos";
import {
  formatHourDateToCustomFormat,
  formatToCustomFormat,
} from "@Utilities/formattedDate";
import { formatToSoles } from "@Utilities/FormatsHandler";
import {
  ServiceIcon,
  PackageIcon,
  CodeBarIcon,
  CalendarIcon,
} from "@Models/icons";
import {
  IHistoryDetail,
  ISaleDocumentDetail,
  ISaleDocumentResponse,
  IPackageItemResponse,
} from "@Interfaces/sales";
import { useState, useEffect } from "react";
import { useClientsContext } from "@Contexts/clientsContext";
import { adaptSaleDocumentDetail } from "@Adapters/adaptSaleDocumentDetail";
import { HistoryRowDetailsSkeleton } from "./HistoryRowDetailsSkeleton";

const getIconByType = (type: string) => {
  switch (type) {
    case "SERVICE":
      return ServiceIcon;
    case "PACKAGE":
      return PackageIcon;
    case "PRODUCT":
      return CodeBarIcon;
    case "APPOINTMENT":
    case "COMMAND":
      return CalendarIcon;
    default:
      return null;
  }
};

const getPackageItemIcon = (item: IPackageItemResponse) => {
  if (item.packageLocalProductId) return CodeBarIcon;
  if (item.packagePriceServiceId) return ServiceIcon;
  return null;
};

export const HistoryRowDetails = ({
  showModalDetailsHistory,
  setShowModalDetailsHistory,
}: {
  showModalDetailsHistory: IHistoryDetail | null;
  setShowModalDetailsHistory: (detail: IHistoryDetail | null) => void;
}) => {
  const [saleDetails, setSaleDetails] = useState<ISaleDocumentResponse | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(false);
  const { setNotification } = useClientsContext();

  useEffect(() => {
    const fetchSaleDetails = async () => {
      if (
        showModalDetailsHistory?.documentId &&
        showModalDetailsHistory?.saleBoxId
      ) {
        setIsLoading(true);
        const response = await adaptSaleDocumentDetail(
          showModalDetailsHistory.documentId,
          showModalDetailsHistory.saleBoxId
        );

        if (response.isSuccess && response.data) {
          setSaleDetails(response.data);
        } else {
          setNotification({
            message: "Error al obtener los detalles de la venta",
            status: 400,
          });
          setShowModalDetailsHistory(null);
        }
        setIsLoading(false);
      }
    };

    fetchSaleDetails();
  }, [showModalDetailsHistory?.documentId, showModalDetailsHistory?.saleBoxId]);

  if (!showModalDetailsHistory?.documentId) return null;
  if (isLoading) return <HistoryRowDetailsSkeleton />;
  if (!saleDetails) return null;

  console.log(showModalDetailsHistory);
  console.log("saleDetails", saleDetails);

  const getItemType = (item: ISaleDocumentDetail) => {
    if (item.packageId) return "PACKAGE";
    if (item.localProductId) return "PRODUCT";
    if (item.priceServiceId) {
      if (item.reserveDetailId) return "APPOINTMENT";
      if (item.comandaDetailId) return "COMMAND";
      return "SERVICE";
    }
    return "UNKNOWN";
  };

  return (
    <ModalAcceptCancel
      title={"Detalles de la venta"}
      showButtons={{
        showButtonClose: true,
        showButtonOne: false,
        showButtonTwo: false,
      }}
      actionButtonClose={() => setShowModalDetailsHistory(null)}
    >
      <div className={style.containerDetailsHistory}>
        <div className={style.headerSection}>
          <article>
            <p>N° de documento</p>
            <div>
              <p>{showModalDetailsHistory.documentNumber}</p>
            </div>
          </article>
          <article>
            <p>Fecha y hora</p>
            <div>
              <p>
                {formatToCustomFormat(showModalDetailsHistory.dateTime)} -{" "}
                {formatHourDateToCustomFormat(showModalDetailsHistory.dateTime)}
              </p>
            </div>
          </article>
          <article>
            <p>Cajero</p>
            <div>
              <p>{showModalDetailsHistory.employeeName}</p>
            </div>
          </article>
          {/* <article>
						<p>Empleado asignado</p>
						<div>
							<p>{showModalDetailsHistory.assignedEmployee}</p>
						</div>
					</article> */}
        </div>

        <div className={style.paymentSection}>
          <div className={style.sectionTitle}>Medios de pago</div>
          {saleDetails.paymentMethods.map((payment, index) => (
            <article key={index}>
              <p>{payment.name}</p>
              <div>
                <p>{formatToSoles(payment.amount)}</p>
              </div>
            </article>
          ))}
        </div>

        <div className={style.saleDetailsSection}>
          <div className={style.sectionTitle}>Detalle de la venta</div>

          {/* Servicios individuales */}
          {saleDetails.groupedItems.services.length > 0 && (
            <div className={style.itemGroup}>
              <h4>Servicios</h4>
              {saleDetails.groupedItems.services.map((item, index) => (
                <div key={`service-${index}`} className={style.saleItem}>
                  <div className={style.itemHeader}>
                    <div className={style.itemInfo}>
                      <Icon path={ServiceIcon} size={16} color={"#45348e"} />
                      <p>{item.description}</p>
                    </div>
                    <span className={style.headerSeparator} />
                    <p className={style.total}>{formatToSoles(item.total)}</p>
                  </div>
                </div>
              ))}
            </div>
          )}

          {/* Productos */}
          {saleDetails.groupedItems.products.length > 0 && (
            <div className={style.itemGroup}>
              <h4>Productos</h4>
              {saleDetails.groupedItems.products.map((item, index) => (
                <div key={`product-${index}`} className={style.saleItem}>
                  <div className={style.itemHeader}>
                    <div className={style.itemInfo}>
                      <Icon path={CodeBarIcon} size={16} color={"#45348e"} />
                      <p>
                        {item.description} (x{item.quantity})
                      </p>
                    </div>
                    <span className={style.headerSeparator} />
                    <p className={style.total}>{formatToSoles(item.total)}</p>
                  </div>
                </div>
              ))}
            </div>
          )}

          {/* Paquetes */}
          {saleDetails.groupedItems.packages.length > 0 && (
            <div className={style.itemGroup}>
              <h4>Paquetes</h4>
              {saleDetails.groupedItems.packages.map((item, index) => (
                <div key={`package-${index}`} className={style.saleItem}>
                  <div className={style.itemHeader}>
                    <div className={style.itemInfo}>
                      <Icon path={PackageIcon} size={16} color={"#45348e"} />
                      <p>{item.description}</p>
                    </div>
                    <span className={style.headerSeparator} />
                    <p className={style.total}>{formatToSoles(item.total)}</p>
                  </div>
                  {item.itemPackageResponse.length > 0 && (
                    <div className={style.groupItems}>
                      {item.itemPackageResponse.map((subItem, idx) => (
                        <div key={idx} className={style.saleItem}>
                          <div className={style.itemHeader}>
                            <div className={style.itemInfo}>
                              <Icon
                                path={getPackageItemIcon(subItem)}
                                size={12}
                                color={"#666"}
                              />
                              <p>{subItem.packageItemDescription}</p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}

          {/* Reservas */}
          {Object.keys(saleDetails.groupedItems.appointments).length > 0 && (
            <div className={style.itemGroup}>
              <h4>Reservas</h4>
              {Object.entries(saleDetails.groupedItems.appointments).map(
                ([reserveId, items]) => (
                  <div
                    key={`appointment-${reserveId}`}
                    className={style.saleItem}
                  >
                    <div className={style.itemHeader}>
                      <div className={style.itemInfo}>
                        <Icon path={CalendarIcon} size={16} color={"#45348e"} />
                        <p>Reserva #{reserveId}</p>
                      </div>
                      <span className={style.headerSeparator} />
                      <p className={style.total}>
                        {formatToSoles(
                          items.reduce((sum, item) => sum + item.total, 0)
                        )}
                      </p>
                    </div>
                    <div className={style.groupItems}>
                      {items.map((item, index) => (
                        <div
                          key={`appointment-item-${index}`}
                          className={style.saleItem}
                        >
                          <div className={style.itemHeader}>
                            <div className={style.itemInfo}>
                              <Icon
                                path={ServiceIcon}
                                size={12}
                                color={"#666"}
                              />
                              <p>{item.description}</p>
                            </div>
                            <p>{formatToSoles(item.total)}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )
              )}
            </div>
          )}

          {/* Comandas */}
          {Object.keys(saleDetails.groupedItems.commands).length > 0 && (
            <div className={style.itemGroup}>
              <h4>Comandas</h4>
              {Object.entries(saleDetails.groupedItems.commands).map(
                ([commandId, items]) => (
                  <div key={`command-${commandId}`} className={style.saleItem}>
                    <div className={style.itemHeader}>
                      <div className={style.itemInfo}>
                        <Icon path={CalendarIcon} size={16} color={"#45348e"} />
                        <p>Comanda #{commandId}</p>
                      </div>
                      <span className={style.headerSeparator} />
                      <p className={style.total}>
                        {formatToSoles(
                          items.reduce((sum, item) => sum + item.total, 0)
                        )}
                      </p>
                    </div>
                    <div className={style.groupItems}>
                      {items.map((item, index) => (
                        <div
                          key={`command-item-${index}`}
                          className={style.saleItem}
                        >
                          <div className={style.itemHeader}>
                            <div className={style.itemInfo}>
                              <Icon
                                path={ServiceIcon}
                                size={12}
                                color={"#666"}
                              />
                              <p>{item.description}</p>
                            </div>
                            <p>{formatToSoles(item.total)}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )
              )}
            </div>
          )}
        </div>

        <div className={style.totalSection}>
          <article>
            <p>Subtotal</p>
            <div>
              <p>{formatToSoles(saleDetails.saleDocument.subTotal)}</p>
            </div>
          </article>
          <article>
            <p>IGV</p>
            <div>
              <p>{formatToSoles(saleDetails.saleDocument.igv)}</p>
            </div>
          </article>
          <article>
            <p>ICBPER</p>
            <div>
              <p>{formatToSoles(saleDetails.saleDocument.icbper)}</p>
            </div>
          </article>
          <article>
            <p>Descuento</p>
            <div>
              <p>{formatToSoles(saleDetails.saleDocument.discount)}</p>
            </div>
          </article>
          <article className={style.totalAmount}>
            <p>Total</p>
            <div>
              <p>{formatToSoles(saleDetails.saleDocument.total)}</p>
            </div>
          </article>
        </div>
      </div>
    </ModalAcceptCancel>
  );
};
