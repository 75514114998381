import { useParams } from "react-router-dom";
import { useHistoryFilters } from "@Contexts/historyFiltersContext";
import { adaptCustomerHistoryExport } from "@Adapters/adaptCustomerHistoryExport";
import { useClientsContext } from "@Contexts/clientsContext";
//@ts-ignore
import { DateInput, ButtonWithoutIcon } from "@viuti/recursos";
import { MultiSelect } from "@Components/MultiSelect/MultiSelect";
import style from "./HistoryFilters.module.css";

const SALE_CATEGORIES = [
  { value: "1", label: "Servicios" },
  { value: "3", label: "Paquetes" },
  { value: "2", label: "Productos" },
  { value: "4", label: "Reservas" },
  { value: "5", label: "Comandas" },
];

export const HistoryFilters = () => {
  const { id: clientId } = useParams();
  const { filters, updateFilters, isExporting, setIsExporting } =
    useHistoryFilters();
  const { setNotification } = useClientsContext();

  const handleCategoriesChange = (selectedValues: string[]) => {
    updateFilters({
      ...filters,
      categories: selectedValues,
    });
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    updateFilters({
      ...filters,
      dateRange: {
        ...filters.dateRange,
        [name === "startDate" ? "startDate" : "endDate"]: value
          ? new Date(value)
          : null,
      },
    });
  };

  const selectedCategories = SALE_CATEGORIES.filter((category) =>
    filters.categories.includes(category.value)
  );

  const handleExport = async () => {
    if (isExporting) return;

    setIsExporting(true);
    try {
      const response = await adaptCustomerHistoryExport(clientId, filters);
      setNotification({
        message: response.isSuccess
          ? "Historial exportado exitosamente"
          : "Error al exportar el historial",
        status: response.isSuccess ? 200 : 400,
      });
    } catch (error) {
      setNotification({
        message: "Error al exportar el historial",
        status: 400,
      });
    }
    setIsExporting(false);
  };

  return (
    <div className={style.filtersContainer}>
      <div className={style.filterGroup}>
        <MultiSelect
          name="categories"
          label="Categorías"
          options={SALE_CATEGORIES}
          value={filters.categories}
          onChange={handleCategoriesChange}
          placeholder="Seleccionar categorías"
          disabled={isExporting}
        />
      </div>

      <div className={style.dateSection}>
        <DateInput
          label="Desde"
          inputProps={{
            placeholder: "dd/mm/yyyy",
            value:
              filters.dateRange.startDate?.toISOString().split("T")[0] || "",
            onChange: handleDateChange,
            name: "startDate",
            max: filters.dateRange.endDate?.toISOString().split("T")[0],
            disabled: isExporting,
          }}
        />
        <DateInput
          label="Hasta"
          inputProps={{
            placeholder: "dd/mm/yyyy",
            value: filters.dateRange.endDate?.toISOString().split("T")[0] || "",
            onChange: handleDateChange,
            name: "endDate",
            min: filters.dateRange.startDate?.toISOString().split("T")[0],
            disabled: isExporting,
          }}
        />
        <ButtonWithoutIcon
          handleClick={handleExport}
          isPrimary
          textBttn="Exportar CSV"
          isLoading={isExporting}
          isDisabled={isExporting}
        />
      </div>
    </div>
  );
};
