import { ApiResponse, baseUrl, fetchData } from "@Services/constServices";
import { IHistoryFilters } from "@Contexts/historyFiltersContext";

export const getCustomerHistoryExport = async (
  idClient: string,
  filters: IHistoryFilters
): Promise<ApiResponse> => {
  const queryParams = new URLSearchParams({
    customerId: idClient,
  });

  if (filters?.categories?.length) {
    queryParams.append("categoryId", filters.categories.join("-"));
  }

  if (filters?.dateRange?.startDate) {
    const startDate = filters.dateRange.startDate.toISOString().split("T")[0];
    queryParams.append("startDate", startDate);
  }

  if (filters?.dateRange?.endDate) {
    const endDate = filters.dateRange.endDate.toISOString().split("T")[0];
    queryParams.append("endDate", endDate);
  }

  const url = `${baseUrl}/ExportCustomerHistory?${queryParams.toString()}`;
  return fetchData(url);
};
